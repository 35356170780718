import * as React from "react"
import MyLink from "../components/link"
import ContactForm from "../components/contactForm"
import IconPhone from "../assets/img/icons/phone.svg"
import IconEmail from "../assets/img/icons/envelope.svg"
import IconFacebook from "../assets/img/icons/facebook.svg"
import IconLinkedin from "../assets/img/icons/linkedin.svg"

export default function Page() {
    return (
        <div className="my-16 contact-page double-padding-bottom">
            <div className="container flex flex-wrap">
                <div className="w-full lg:w-5/12">
                    <h1><span>Contact</span> Us.</h1>
                    <p className="text-larger">
                        <a className="text-white" href="tel:27861377704">
                            <IconPhone className="icon-white icon-inline" />+27 (0) 86 137 7704
                        </a><br />
                        <a className="text-white" href="mailto:info@artificial.studio">
                            <IconEmail className="icon-white icon-inline" />info@artificial.studio
                        </a>
                    </p>
                    <p>
                        <strong>Office Hours:</strong><br />
                        Monday - Friday, 9:00 - 17:00 (GMT+2)
                    </p>
                    <table className="mb-6 text-sm">
                        <tbody>
                            <tr>
                                <td><strong>General Enquiries & Quotes:</strong></td>
                                <td className="pl-4"><a href="mailto:info@artificial.studio">info@artificial.studio</a></td>
                            </tr>
                            <tr>
                                <td><strong>Managing Director:</strong></td>
                                <td className="pl-4"><a href="mailto:leroux@artificial.studio">leroux@artificial.studio</a></td>
                            </tr>
                            <tr>
                                <td><strong>Maintenance & Support:</strong></td>
                                <td className="pl-4"><a href="mailto:gernot@artificial.studio">gernot@artificial.studio</a></td>
                            </tr>
                            <tr>
                                <td><strong>Digital Marketing:</strong></td>
                                <td className="pl-4"><a href="mailto:nicola@artificial.studio">nicola@artificial.studio</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        <a aria-label="Facebook" href="https://www.facebook.com/artificialstudio" target="noreferrer"><IconFacebook className="icon-white icon-inline" /></a>
                        <a aria-label="LinkedIn" href="https://za.linkedin.com/company/artificial-studio-cc" target="noreferrer"><IconLinkedin className="icon-white icon-inline" /></a>
                    </p>
                    <p>
                        <MyLink to="/get-a-quote/" className="btn btn-lg btn-blue mt-8">Get A Quote</MyLink>
                    </p>
                </div>
                <div className="hidden lg:w-1/12 lg:flex relative">
                    <div className="dashed-vertical"></div>
                    <div className="dashed-bottomleft"></div>
                </div>
                <div className="w-full lg:hidden"><div className="dashed mt-4"></div></div>
                <div className="w-full lg:w-5/12 mt-12 lg:mt-0 lg:justify-center lg:flex">
                    <ContactForm title="Send us a message" />
                </div>
                <div className="hidden lg:w-1/12 lg:flex relative">
                    <div className="dashed-vertical"></div>
                    <div className="dashed-topright"></div>
                </div>
            </div>
        </div>
    )
}
